import React from "react"
import { SEO as Seo } from '../components/SEO.js'
import { Container } from "react-bootstrap"
import styled from "styled-components"
import Layout from "../components/Layout"
import ProjectEstimationConsultation from "../components/ProjectEstimationConsultation"
import ProjectEstimationHeader from "../components/ProjectEstimationHeader"

const thankYou = () => {
  return (
    <Layout>
      <Seo 
        title="Blackfort - Thank you for your submission"
      />
      <StyledContainer fluid>
        <ProjectEstimationHeader />
        <Wrapper>
          <Success>Thank you for your submission!</Success>
        </Wrapper>
        <ProjectEstimationConsultation />
      </StyledContainer>
    </Layout>
  )
}

export default thankYou

// Styled Component

const Success = styled.h1`
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 3em;
`

const StyledContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
`

const Wrapper = styled.div`
  display: flex;
  padding-left: 20%;
  padding-right: 20%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
